.cancel-image {
    position: relative;
    display: inline-block;
}

.cancel-image i {
    position: absolute;
    background-color: rgb(179, 24, 24);
    color: white;
    border-radius: 50%;
    cursor: pointer;
    right: -6px;
    top: -6px;
    height: 24px;
    width: 24px;
}