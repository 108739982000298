.film-detail {
	text-align: center;
}

.film-detail p {
    text-align: justify;
}

.film-detail p span {
    font-weight: bold;
}

.film-detail .arrow {
	color: grey;
	position: fixed;
    top: 50%;
    transform: translateY(-50%);
}

.film-detail .arrow.left {
	left: 20px;
}

.film-detail .arrow.right {
	right: 20px;
}

.gallery {
    margin: 70px 0;
}

.film-wrapper {
    position: relative;
	padding-bottom: 50%;
	/* padding-top: 25px; */
	height: 0;
}

.film-wrapper iframe {
    position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}