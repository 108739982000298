.films {
    display: grid;
    margin-bottom: 0;
}

@media (min-width: 992px) {
    .films {
        grid-template-columns: repeat(2, 1fr);
    }
}
