.about {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profile {
    margin-top: 1.5rem;
    padding-bottom: 1rem;
    box-shadow: 1px 0 2px rgb(192, 192, 192);
}

.profile:hover {
    box-shadow: 2px 2px 5px 3px rgb(192, 192, 192);
    transition: box-shadow 300ms;
}

.contact {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.contact i {
    color: rgb(53, 53, 53);
    cursor: pointer;
}

.description {
    margin: 12px 0;
    text-align: justify;
}

.contact-icon-containter {
    display: flex;
    align-items: center;
}

.inactive {
    display: none;
}

.active {
    margin: 4px;
}

.contact i.icon-active {
    color: rgb(81, 126, 223);
}