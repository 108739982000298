.film-item {
    padding: 0 !important;
    /* overflow: hidden; */
    /* min-height: 300px; */
    /* max-height: 500px; */
    /* height: auto; */
    position: relative;
}

.film-item img {
    height: 100%;
    width: 100%;
    box-shadow: 0px 0px 40px 15px black;
    object-fit: cover;
}

.film-item .title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background-color: rgba(36, 36, 36, 0.7);
}

.film-item .title .content {
    color: white;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

/* Effects for the square */
.film-item .title.initial-title {
    opacity: 0;
}

.film-item .title.show-title {
    animation: show 1s;
}

.film-item .title.hide-title {
    animation: hide 1s forwards;
}

@keyframes show {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes hide {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        display: none;
    }
}