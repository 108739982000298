ul.collection.films-admin {
    margin: 0;
}

.admin-film .admin-film-list {
    position: relative;
    overflow: auto;
    min-height: 100%;
    max-height: 100%;
    /* height: 60vh; */
}

.film-enter {
    opacity: 0;
}

.film-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.film-exit {
    opacity: 1;
    transform: scale(1, 1);
}
  
.film-exit-active {
    animation: exit 500ms ease-in;
}

@keyframes exit {
    from {
        opacity: 1;
        transform: scale(1, 1);     
    }

    20% {
        opacity: 0.8;
        transform: scale(1.1, 1.1);    
    }

    to {
        opacity: 0;
        transform: scale(0, 0);
    }
}