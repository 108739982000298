.image-ratio-crop {
    display: inline-block;
    position: relative;
    padding-bottom: -10px;
}

.image-ratio-crop .top, .image-ratio-crop .left, .image-ratio-crop .bottom, .image-ratio-crop .right {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
}

.image-ratio-crop .center {
     position: absolute;
     border: 3px dashed white;
}

.image-ratio-crop .circle-pointer {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: #2e2e2e;
    border: 1px solid white;
    border-radius: 50%;
}

.image-ratio-crop .center:hover {
    cursor: grab;
}

.image-ratio-crop .circle-pointer:hover {
    cursor: nwse-resize;
}
