.admin-about{
    margin-top: 10px;
    position: relative;
    min-height: 60vh;
}

.admin-about .card-panel {
    padding: 0;
}

.admin-about td {
    padding: 0;
}

.admin-about tr td input {
    border-bottom: none !important;
    height: 2rem !important;
}


.admin-about .content {
    text-align: justify;
}

.admin-about .contacts {
    margin-bottom: 20px;
}

.admin-about .action-buttons {
    position: absolute;
    bottom: 0;
    right: 0;
}