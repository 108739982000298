.login-container {
    background-color: rgb(175, 175, 175);
    height: 100vh;
    width: 100vw;
}

.login {
    position: absolute;
    top :50%;
    left: 50%;
    right: 30%;
    transform: translate(-50%, -50%);
    padding: 50px;
    width: 50%;
    min-width: 400px;
    max-width: 500px;
}