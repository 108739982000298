.admin {
    background-color: #d1d1d1;
}

.admin .row .col {
    height: 100%;
}

.admin .admin-title {
    margin-bottom: 0;
}

.admin .admin-title .card-panel {
    margin-bottom: 0;
}


.row .admin-film-column {
    height: 100%;
}